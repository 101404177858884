import React from 'react';
import styled from '@emotion/styled';

const ContactSubmissionsSection = styled.section`
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    max-width: 880px;
    width: 80%;

    @media (max-width: 800px) {
        margin-top: 2rem;
        width: 100%;
    }

    p:nth-of-type(2) {
        margin-top: 1.5rem;
        font-size: 0.75rem;
    }
`

const SubmissionsTitle = styled.h2`
    font-family: ${props => props.theme.fonts.title};
    font-size: 1.5rem;
    margin-bottom: 1rem;
`;

const SubmissionsCopy = styled.p`
    font-family: ${props => props.theme.fonts.copy};
    font-size: 1.25rem;
    line-height: 1.5;
    text-align: center;

    @media (max-width: 500px) {
        font-size: 1.125rem;
    }
`

const SubmissionsLink = styled.a`
    background-color: ${props => props.theme.colors.highlight};
    border-radius: 0.469rem;
    color: #000;
    font-family: ${props => props.theme.fonts.title};
    font-size: 1.25rem;
    margin: 1.875rem auto 0 auto;
    max-width: 550px;
    padding: 1.875rem 0.9rem;
    text-align: center;
    text-decoration: none;
    transition: ${props => props.theme.effects.transition};
    width: 70%;

    &:hover {
        box-shadow: ${props => props.theme.effects.boxShadow};
        cursor: pointer;
    }

    @media (max-width: 950px) {
        max-width: 355px;
        width: calc(100% - 1.25rem);
    }
`


const ContactSubmissions = () => {
    return (
        <ContactSubmissionsSection>
            <SubmissionsTitle>Submissions</SubmissionsTitle>
            <SubmissionsCopy>To submit a piece directly, use the link below. It will take you to a Google Form page where you can upload files safely. After a short quality review, it will be posted to the site and pushed on our social media channels. We’re looking forward to hearing from you!</SubmissionsCopy>
            <SubmissionsLink href="https://forms.gle/1DKLdkZ3AJ6Rc5fp6" target="_blank">Send Submission</SubmissionsLink>
        </ContactSubmissionsSection>
    )
};

export default ContactSubmissions;