import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Layout from '../components/Layout'
import Container from '../components/Container'
import ContactForm from '../components/Contact/ContactForm'
import ContactIntro from '../components/Contact/ContactIntro';
import ContactSubmissions from '../components/Contact/ContactSubmissions';
import PageTitle from '../components/Post/PageTitle'
import SEO from '../components/SEO'
import { updateSearchValue } from '../actions/valueChanges';

const ContactDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 800px) {
    align-items: center;
  }

  #data {
    display: none;
  }
`

const Contact = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateSearchValue(""));
  }, [])
  
  return (
    <Layout>
      <SEO title="Contact" description="Contribute by filling out a form on this page" />
      <Container>
        <PageTitle setClassName={'contact-page'}>Contact</PageTitle>
        <ContactDetailsContainer>
          <ContactIntro />
          <ContactForm />
          <ContactSubmissions />
        </ContactDetailsContainer>
      </Container>
    </Layout>
  )
}

export default Contact


export const query = graphql`
  query {
    contentfulAsset(title: { eq: "Email Gif" }) {
      id
      fluid(maxWidth: 300) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
      title
    }
  } 
`