import React from 'react';
import styled from '@emotion/styled';

const ContactCopySection = styled.section`
    margin-bottom: 4rem;
    max-width: 880px;
    width: 80%;

    p {
        &:nth-of-type(2) {
            margin: 1rem 0;
        }
    }

    @media (max-width: 800px) {
        margin-top: 2rem;
        width: 100%;
    }
`
    const ContactCopy = styled.p`
    font-family: ${props => props.theme.fonts.copy};
    font-size: 1.25rem;
    line-height: 1.5;
    text-align: center;

    @media (max-width: 500px) {
        font-size: 1.125rem;
    }
`


const ContactIntro = () => {
    return (
        <ContactCopySection>
            <ContactCopy>
                Have an interesting story, take, review, interest, ANYTHING and want to share it with the world? Eklectish can help.
            </ContactCopy>
            <ContactCopy>
                As long as there is quality and good faith in your content, it interests us and will be posted on Eklectish. People from any background or experience can publish their ideas on Eklectish. Whether it is a 200-word review of a fantastic local restaurant or a 15,000 word essay on European tax policy, it is this exact eclectic mix of content that is creating a platform for readers to engage with new perspectives and diversify their own interests.
            </ContactCopy>
            <ContactCopy>
                If you want to talk to someone on the Eklectish team regarding a potential piece or podcast, or just to provide general feedback, fill out the form below and we’ll reach out shortly to arrange a call! 
            </ContactCopy>
        </ContactCopySection>
    )
};

export default ContactIntro;